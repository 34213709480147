import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const CategoryPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);

  const getInfo = () => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `support/category/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      context.post(
        `support/category/${id}`,
        {
          name: info?.name || '',
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          setInfo(null);
          onClose(id == 0);
        }
      );
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.category.category_save}
        </ModalHeader>
        <ModalBody>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.name}</label>
            <div className="col-xs-9">
              <input
                required
                type='text'
                className='form-control'
                autoComplete='off'
                value={info?.name || ''}
                onChange={e => setInfo({ ...info, name: e.target.value })} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CategoryPopup;
